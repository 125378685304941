<template>
  <div class="page page--connect">
    <Header
      title="'Rollee Ride'"
      :hasClose="true"
      :hasBack="getConnectedSources?.length > 0"
      @goBack="goBack"
    />
    <div class="page__content">
      <div class="page__connect" :class="{ sources: getConnectedSources?.length > 0 }">
        <div class="page__input" v-if="getConfig.search_input_show">
          <SearchField
            class="page__search-input page__search-input--faster"
            :dataPlaceholder="getConfig.search_page_title"
            @update="searchValue"
          />
        </div>
        <div class="page__filters" v-if="showFilters">
          <button
            class="button--small button--is-filter"
            :class="{ 'button--is-filter--active': selectedCategory === 'all' }"
            @click="getSourceCategory('all')">
            All
          </button>
          <button
            class="button--small button--is-filter"
            :class="{ 'button--is-filter--active': selectedCategory === 'popular' }"
            @click="getSourceCategory('popular')">
            Popular
          </button>
          <button
            class="button--small button--is-filter"
            :class="{ 'button--is-filter--active': selectedCategory === 'gig' }"
            @click="getSourceCategory('gig')">
            Gig
          </button>
          <button
            class="button--small button--is-filter"
            :class="{ 'button--is-filter--active': selectedCategory === 'payroll' }"
            @click="getSourceCategory('payroll')">
            Payroll
          </button>
          <button
            class="button--small button--is-filter"
            :class="{ 'button--is-filter--active': selectedCategory === 'freelance' }"
            @click="getSourceCategory('freelance')">
            Freelance
          </button>
        </div>
        <div class="page__list" v-if="getAvailableSources.sources">
          <ul v-if="filteredSource.length">
            <li
              v-for="(source, index) in filteredSource"
              :key="source.name"
              :style="getAnimationDelay(index)"
              @click="connect(source)"
              :class=" {
                'page__list--merged': source?.merged_name,
              }"
            >
              <div class="page__list-icon">
                <img
                  v-if="source?.merged_logo"
                  :src="source.merged_logo"
                  :alt="source.merged_name" />
                <img
                  v-if="!source?.merged_logo && getAvatar(source)"
                  :src="getAvatar(source)"
                  :alt="source.name" />
                <div
                  v-if="!source?.merged_logo && !!getAvatar(source)"
                  class="page__list-default" />
              </div>
              <div class="page__list-name">
                <h2 v-html="source?.merged_name || source.name" />
              </div>
              <div class="page__list-action">
                <CarrotRightIcon />
              </div>
            </li>
            <li @click="contact()">
              <div class="page__list-icon">
                <div class="page__list-icon--empty">
                  <RightCornerArrowIcon />
                </div>
              </div>
              <div class="page__list-name page__list-name--smaller">
                <h2><Translate :translate="'searchAllPlatform'" /></h2>
                <p><Translate :translate="'cantFindPlatform'" /></p>
              </div>
            </li>
          </ul>
          <div class="page__list--empty" v-else>
            <div class="page__list">
              <ul>
                <li>
                  <div>
                  </div>
                  <div class="page__list-name page__list--connected ">
                    <h2><Translate :translate="'searchAllPlatformsConnectd'" /></h2>
                    <p><Translate :translate="'searchAllPlatformsConnectdDescription'" /></p>
                  </div>
                </li>
                <li @click="contact()">
                  <div class="page__list-icon">
                    <div class="page__list-icon--empty">
                      <RightCornerArrowIcon />
                    </div>
                  </div>
                  <div class="page__list-name page__list-name--smaller">
                    <h2><Translate :translate="'searchAllPlatform'" /></h2>
                    <p><Translate :translate="'cantFindPlatform'" /></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else>
          <img class="page__loader" src="/img/loading.gif" alt="Loading" />
        </div>
        <ConnectedSources class="page__connect-sources" v-if="getConnectedSources?.length > 0" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import Header from '@/components/Header';
import RightCornerArrowIcon from '@/components/icons/RightCornerArrowIcon';
import CarrotRightIcon from '@/components/icons/CarrotRightIcon';
import ConnectedSources from '@/components/ConnectedSources';
import SearchField from '../components/SearchField';

export default {
  name: 'Search',
  components: {
    Header,
    SearchField,
    CarrotRightIcon,
    RightCornerArrowIcon,
    ConnectedSources,
  },
  params: ['searchFocus'],
  data() {
    return {
      data: [],
      searchWord: undefined,
      selectedCategory: 'all',
    };
  },
  computed: {
    ...mapGetters(['getAvailableSources', 'getConnectedSources', 'getConfig']),
    categorySources() {
      if (this.selectedCategory === 'all') {
        return this.getAvailableSources.sources;
      }

      return this.getAvailableSources.sources.filter(
        (source) => source.category === this.selectedCategory,
      );
    },
    showFilters() {
      if (typeof this.getAvailableSources !== 'undefined' && !isEmpty(this.getAvailableSources) && this.getAvailableSources.sources !== undefined) {
        return this.getAvailableSources?.sources[0]?.category !== undefined;
      }
      return false;
    },
    filteredSource() {
      if (this.getAvailableSources.sources !== null && !isEmpty(this.getAvailableSources) && this.getAvailableSources.sources !== undefined) {
        // eslint-disable-next-line
        const reducedSources = this.categorySources.filter((source) => {
          // eslint-disable-next-line
          if(source.multiple){
            return true;
          }
          // filter reducedSources by last-fetch if complete filter out
          if ((source['last-fetch'] && source['last-fetch'].status === 'complete') && !source.multiple) {
            return false;
          }

          if (!this.getConnectedSources?.some(
            (connectedSource) => connectedSource.name === source.name,
          )) {
            return true;
          }
          return false;
        });
        if (this.searchWord) {
          // eslint-disable-next-line
          return reducedSources.filter((source) => source.name.toLowerCase().includes(this.searchWord.toLowerCase()));
        }
        return reducedSources;
      }
      return [];
    },
  },
  mounted() {
    this.$store.commit('SET_ERROR', null);
  },
  methods: {
    getAnimationDelay(index) {
      return {
        'animation-delay': `${index * 0.14}s`,
      };
    },
    connect(source) {
      this.$store.commit('SET_SELECTED_SOURCE', source);
      // Check if data source contains 'ongoing_fetch' id
      // If true skip login screen and start fetch polling with UUID
      if (source['ongoing-fetch']) {
        this.$store.commit('SET_REFRESHED_UUID', source['ongoing-fetch']);
        this.$store.commit('SET_AUTH', true);
        this.$router.push({
          name: 'Fetch',
          query: this.$route.query,
        });
        return;
      }
      // Check if source is acme-rev and redirect to files
      // If not redirect to login
      switch (true) {
        case source.name === "MPESA":
          this.$router.push({
            name: 'Files',
            query: this.$route.query,
          });
          break;
        default:
          this.$router.push({
            name: 'Login',
            query: this.$route.query,
          });
          break;
      }
    },
    searchValue(payload) {
      this.searchWord = payload;
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
       window?.analytics.track('search_search', {
         search: payload,
       });
      }, 500);
    },
    contact() {
      this.$router.push({
        name: 'Contact',
        query: this.$route.query,
      });
      window?.analytics.track('dont_see_search');
    },
    goBack() {
      this.$store.commit('SET_SELECTED_SOURCE', undefined);
      this.$router.push({
        name: 'Terms',
        query: this.$route.query,
      });
    },
    getSourceCategory(selection) {
      this.selectedCategory = selection;
    },
  },
};
</script>
<style lang="scss" scoped>
.page__loader {
  margin: 20px auto;
  height: 34px;
}
</style>
