import { Server } from 'miragejs';
import state from '../store/state';

const partnerMock = {
  connect_fetch_new: '/usr/0.3/fetch/new',
  connect_fetch_set: '/usr/0.3/fetch/set',
  connect_source_all: '/usr/0.3/source/all',
  lang: 'en',
  partner_name: 'zzpierrick',
  partner_title: 'DemoApp',
  person_id: '3e0d0f11-80a7-40da-9590-1c86d06ca3d0',
  phone_code: '+387',
  product: 'connect',
};

const consentMock = { consent_id: 'demo' };

const connectMock = { account_id: 'a4b60c3d-07d2-40fc-bb27-ceb3acea90db', uuid: 'cdd62f8f-72b2-4c0f-9365-9cb496d50bc1' };

const fetchMock = {
  account_id: 'b5029fa5-0978-4374-92bb-1f8b19dd52e0',
  status: 'connected',
  user_id: '3e0d0f11-80a7-40da-9590-1c86d06ca3d0',
};
// eslint-disable-next-line import/prefer-default-export
export function makeServer() {
  const server = new Server({
    routes() {
      this.namespace = 'api/v0.4/connect';
      this.get('/partner', () => partnerMock);
      this.get('/session_properties', () => {});
      this.get('/sources', async () => {
        const MOCK = [];
        state.config.sources_temp.forEach((source) => {
          MOCK.push({
            ID: source.id,
            id: source.id,
            name: source.title,
            avatar: source.logo,
            url: 'uber.com-fleet-rev',
            'url-title': 'uber.com',
            'login-fields': null,
            'resend-otp': true,
            'forgot-password-url': 'https://auth.uber.com/login/forgot-password?source=auth',
            reverse: true,
            'last-fetch': {},
            multiple: false,
            type: '',
          });
        });
        state.sources = { sources: MOCK };
        return {
          sources: MOCK,
        };
      });
      this.get('fetch/:id', fetchMock);
      this.post('/consent', () => consentMock);
      this.post('/', () => connectMock);
      this.post('https://api.getrollee.com/api/analytics/v0.1/events', () => consentMock);
      this.post('https://api.segment.io/v1/m', () => consentMock);
    },
  });
  return server;
}
