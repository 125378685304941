<template>
  <div class="loading">
    <img src="/img/loading.gif" alt="Loading" />
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
.loading {
  display: grid;
  align-items: center;
  justify-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999;
}

.loading img {
  width: 100px;
}
</style>
