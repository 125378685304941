<template>
  <div class="page page--login" :class="{ rtl: isRtl }">
    <Header :hasClose="true" :hasBack="true" @goBack="goBack" />
    <div class="page__content" v-if="getSelectedSource">
      <div class="login__source"
      :class="{
        'merged': getSelectedSource?.merged_logo,
        }">
        <div
          class="login__source-icon"
          :class="{
            'login__source--merged': getSelectedSource?.merged_logo,
          }">
          <img
            v-if="getSelectedSource.merged_logo"
            ref="sourceLogo"
            :src="getSelectedSource.merged_logo" />
          <img
            v-if="getAvatar(getSelectedSource) && !getSelectedSource.merged_logo"
            ref="sourceLogo"
            :src="getAvatar(getSelectedSource)" />
          <div
            v-if="!getAvatar(getSelectedSource) && !getSelectedSource.merged_logo"
            class="page__list-default" />
        </div>
        <div :dir="textDirection" class="login__source-title">
          <h2>
            <Translate :translate="'logInTo'" />
            <p v-html="getSelectedSource?.merged_name || getSelectedSource.name" />
          </h2>
        </div>
      </div>
      <Tabs
        v-if="tabs.length > 1"
        :tabList="tabs"
        @onTabSelected="onResetLoginData($event)"
        @tabName="tabName = $event">
        <template v-slot:[tab] v-for="tab in tabs">
          <LoginFields
            class="login__form-input"
            :tabName="tab"
            :key="tab"
            @login="loginData = $event"
            @continueEnabled="continueEnabled = $event" />
          </template>
          <template v-slot:providers>
            <ProviderModal  @selected="onProvider" v-if="providers && !getProvider" :data="providers" />
          </template>
        </Tabs>
        <template v-else>
          <LoginFields
          class="login__form-input"
          :tabName="tabs[0]"
          @login="loginData = $event"
          @continueEnabled="continueEnabled = $event" />
      </template>
      <a
        v-if="getTaxInfoDatasources"
        class="page__external-link text-left"
        @click="openTaxModal()">
        <Translate :translate="externalLinkLabel()" />
        <ArrowRightIcon />
      </a>
      <div class="login__form-button">
        <button
          class="button--block"
          :style="`background: ${getConfig.cta_button_backcolor};
                  color: ${getConfig.cta_button_forecolor}`"
          @click="login"
          @keydown.enter="login"
          :disabled="!continueEnabled">
          <Translate :translate="'continue'" />
        </button>
        <button
          v-if="getProvider"
          class="button--block button--secondary button--provider"
          @click="onBackToProviders"
          @keydown.enter="onBackToProviders">
          <Translate :translate="'backToProviders'" />
        </button>
      </div>
      <div class="login__form-button" v-if="getSelectedSource.url === 'heetch.com'">
        <button class="button--block button--facebook" @click="openFacebookModal()">
          <FacebookIcon />
          <Translate :translate="'logInWithFacebook'" />
        </button>
      </div>
      <p class="login__forgot">
        <a @click="forgotCredentials()" v-if="getSelectedSource['forgot-password-url']">
          <Translate :translate="'forgotPassword'" />
        </a>
      </p>
      <ErrorNotification v-if="getError" />
    </div>
    <div class="login__footer-wrapper">
      <TermsOfUseLogin />
    </div>
    <CountryList v-if="getCountryModal" />
    <Modal v-if="getLoadMoreModal" :title="translations.modalProtectDataTitle">
      <div v-html="modalContent"></div>
    </Modal>
    <Modal v-if="getTaxModal" :title="taxModalHeader()">
      <div v-html="getTaxModalContent"></div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Localization from '@/mixins/localization';
import TermsOfUseLogin from '@/components/TermsOfUseLogin';
import Tabs from '@/components/Tabs';
import LoginFields from '@/components/LoginFields';
import Header from '../components/Header';
import CountryList from '../components/CountryList';
import ErrorNotification from '../components/ErrorNotification';
import FacebookIcon from '../components/icons/FacebookIcon';
import Modal from '../components/Modal';
import ArrowRightIcon from '../components/icons/ArrowRightIcon';
import ProviderModal from '../components/ProviderModal';


export default {
  components: {
    Tabs,
    LoginFields,
    TermsOfUseLogin,
    Header,
    CountryList,
    ErrorNotification,
    Modal,
    FacebookIcon,
    ArrowRightIcon,
    ProviderModal,
  },
  name: 'Login',
  mixins: [Localization],
  data() {
    return {
      loginData: {
        country: undefined,
        username: undefined,
        password: undefined,
        provider: undefined,
        date: undefined,
      },
      continueEnabled: false,
      modalContent: null,
      getTaxModalContent: null,
      tabName: null,
      taxInfoDatasources: [
        'agencia-tributaria',
        'impotsgouv-rev',
        'seguridad-social-rev',
        'uber.com-rev',
        'ubereats.com-rev'
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getSelectedSource',
      'getProvider',
      'getAvailableSources',
      'getCountryModal',
      'getCountrySelected',
      'getError',
      'getConfig',
      'getLoginCredentials',
      'getLoadMoreModal',
      'getLocale',
      'translations',
      'getTaxModal',
      'getOnLoginTab',
      'getProvider',
      'getSettings'
    ]),
    providers() {
      const providersData = this.getSelectedSource['login-fields'] && this.getSelectedSource['login-fields'].filter(item => Object.prototype.hasOwnProperty.call(item, 'providers'));

      if (!providersData) {
        return;
      }
      if (providersData.length > 0 && providersData[0].tab === this.tabName) {
        return providersData[0].providers;
      } else {
        return null;
      }
    },
    getTaxInfoDatasources() {
      return this.taxInfoDatasources.includes(this.getSelectedSource.url);
    },
    tabs() {
      if (!this.getSelectedSource['login-fields']) {
        return ['default'];
      }
      // Map required Tab per field,
      const tabsRequired = this.getSelectedSource['login-fields'].map((source) => source?.tab);
      // Reduce Array to uniq tab values
      return [...new Set(tabsRequired)];
    },
    redirectionLink() {
      switch (this.getConfig?.lang) {
        case 'es':
          return 'https://sede.agenciatributaria.gob.es/Sede/ayuda/consultas-informaticas/firma-digital-sistema-clave-pin-tecnica/incidencias-tecnicas-clave-pin/introduzca-soporte-valido.html';
        default:
          return 'https://sede.agenciatributaria.gob.es/Sede/en_gb/ayuda/consultas-informaticas/firma-digital-sistema-clave-pin-tecnica/incidencias-tecnicas-clave-pin/introduzca-soporte-valido.html';
      }
    },
  },
  methods: {
    ...mapActions(['getDataProtectPolicy']),
    openTaxModal() {
      if(this.getTaxModalContent){
        this.getTaxModalContent = null;
      }
      const context = {
        url: this.getSelectedSource.url,
      }
      if(this.tabName){
        context.tabName = this.tabName;
      }


      if(this.getSelectedSource.url === 'uber.com-rev' || this.getSelectedSource.url === 'ubereats.com-rev'){
        this.$store.dispatch('getPasswordInfo', context).then((data) => {
        this.getTaxModalContent = data;
        setTimeout(() => {
          this.$store.commit('SET_TAX_MODAL', true);
        }, 300);
      });
        window?.analytics.track('where_to_find_password');
        return;
      }

      this.$store.dispatch('getTaxInfo', context).then((data) => {
        this.getTaxModalContent = data;
        setTimeout(() => {
          this.$store.commit('SET_TAX_MODAL', true);
        }, 300);
      });
    },
    forgotCredentials() {
      // open new window
      window.open(this.getSelectedSource['forgot-password-url'], '_blank');
      window?.analytics.track('forgot_pass_login');
    },
    onProvider(provider) {
      // This will set the selected source to the provider
      this.$store.commit('SET_PROVIDER', provider);
    },
    onBackToProviders() {
      this.onResetSelectedProvider();
      // Trigger the provider Modal
    },
    goBack() {
      this.$store.commit('SET_LOGIN_TAB', 0);
      this.onResetLoginData();
      if(this.getAvailableSources.sources.length === 1){
        this.$router.push({ name: 'Terms', query: this.$route.query });
        return;
      }
      this.$router.push({ name: 'Search', query: this.$route.query });
    },
    openFacebookModal() {
      this.$store.commit('SET_FACEBOOK_MODAL', true);
    },
    externalLinkLabel() {
      switch (this.getSelectedSource.url) {
        case 'agencia-tributaria':
        case 'seguridad-social-rev':
          if(this.tabName === 'dni'){
            return 'externalLinkAgenciaTributariaDie';
          }
          return 'externalLinkAgenciaTributaria';
          // TODO: Check if on prod if the url is correct
        case 'uber.com-rev':
        case 'ubereats.com-rev':
          return this.translations.externalLinkPassword;
        default:
          return 'externalLinkTaxNumber';
      }
    },
    taxModalHeader(){
      switch (this.getSelectedSource.url) {
        case 'agencia-tributaria':
        case 'seguridad-social-rev':
          if(this.tabName === 'dni'){
            return this.translations.externalLinkAgenciaTributariaDie;
          }
          return this.translations.externalLinkAgenciaTributaria;
        case 'uber.com-rev':
        case 'ubereats.com-rev':
          return this.translations.externalLinkPassword;
        default:
          return this.translations.externalLinkTaxNumber;
      }
    },
    login() {
      if (!this.continueEnabled) {
        return;
      }
      this.$store.commit('SET_AUTH', true);
      this.$store.commit('SET_LOGIN_CREDENTIALS', this.loginData);
      this.$router.push({ name: 'Fetch', query: this.$route.query });
      this.$store.commit('SET_ERROR', null);
      window?.analytics.track('continue_login');
    },
    onResetLoginData() {
      this.loginData = {
        username: undefined,
        date: undefined,
        password: undefined,
        provider: undefined,
      };
      this.continueEnabled = false;
      this.$store.commit('SET_LOGIN_CREDENTIALS', this.loginData);
      this.onResetSelectedProvider();
    },
    onResetSelectedProvider() {
      this.$store.commit('SET_PROVIDER', null);
    },
  },
  mounted() {
    if(this.getSettings.isDemo){
      return;
    }
    // This will push the user forward in history, preventing them from going back from the login page
    // via the browser back button
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    this.getDataProtectPolicy(this.getLocale).then((data) => {
      this.modalContent = data;
    });
  },
  unmounted() {
    this.$store.commit('SET_COUNTRY_SELECTED', undefined);
  },
};
</script>

<style lang="scss" scoped>
.login{
  &__footer{
    &-wrapper{
      display: flex;
      align-items: flex-end;
    }
  }
}

.button{
  &--provider{
    margin-top: 1rem;
  }
}
</style>
