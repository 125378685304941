import { isNil, merge } from 'lodash';
import Data from '../api/data';
import types from './mutationTypes';

const availableTermsAndPolicies = ['en','fr','es','ar'];

export default {

  async getSources({ commit, state }) {
    try {
      const result = await Data.getSources(state.config);
      if (isNil(result)) {
        throw new Error('getSourceError');
      }
      commit(types.SET_SOURCES, result);
      commit(types.SET_CONNECTED_SOURCES, result['sources-connected']);
      commit(types.SET_SUGGESTED_SOURCES, result['sources-suggested']);
      return result;
    } catch (error) {
      return error;
    }

  },
  async sendEmail({ commit, state }, payload) {
    try {
      const result = await Data.sendEmail(state.config, payload);
      if (isNil(result)) {
        throw new Error('sendEmailError');
      }
      return true;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        description: error.error,
      });
      return undefined;
    }
  },
  async connectToSource({ commit, state }, source) {
    try {
      const result = await Data.connectToSource(
        state.config,
        source,
        state.loginCredentials,
      );
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async getConfiguration({ state }) {
    try {
      const result = await Data.getConfiguration(state.config);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      state.config = merge(state.config, result);

      // Check if result contains customization and merge it into config
      if (result.customization) {
        state.config = merge(state.config, result.customization);
        if (state.config.search_available_datasources.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          state.config.datasource = state.config.search_available_datasources[0];
        }
      }
      if(result.success_available_datasources !== undefined && state.config.skip_datasource_check !== true){
        state.config.success_available_datasources = true;
      }

      return result;
    } catch (error) {
      return error;
    }
  },
  async getSessionInfo({ commit, state }) {
    try {
      const result = await Data.getSessionInfo(state.config);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      state.config = merge(state.config, result);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message.includes('401') ? 'Your token has expired!' : '',
        description: 'Your session has timed out for security purposes, please try again or contact your administrator',
        show: true,
      });
      return error;
    }
  },
  async sendConfirmationCode({ commit, state }, code) {
    try {
      const result = await Data.sendConfirmationCode(state.config, code, state.fetchUUID);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message.includes('401') ? 'Your token has expired!' : '',
        description: error.message,
        show: true,
      });
      return undefined;
    }
  },
  async sendApprovalMFA({ commit, state }) {
    try {
      // ! Send empty string for Approval
      const result = await Data.sendConfirmationCode(state.config, '', state.fetchUUID);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message.includes('401') ? 'Your token has expired!' : '',
        description: error.message,
        show: true,
      });
      return undefined;
    }
  },
  async sendConsentAccepted({ commit, state }, payload) {
    try {
      const result = await Data.sendConsent(state.config, payload);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message.includes('401') ? 'Your token has expired!' : '',
        description: error.message,
        show: true,
      });
      return undefined;
    }
  },
  async setClientName({ commit, state }, name) {
    try {
      const result = await Data.setClientName(state.config, name);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      state.config = merge(state.config, result);
      state.clientName = name;
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async loopForStatus({ commit, state }, fetchUUID) {
    try {
      const result = await Data.loopForStatus(state.config, fetchUUID);
      if (isNil(result)) {
        throw new Error('connectToSourceError');
      }
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async getTermsOfUse({ commit, state }, locale) {
    if(!availableTermsAndPolicies.includes(locale)){
      locale = 'en';
    }
    try {
      const result = await Data.getTermsOfUse(state.config, locale);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async getDataProtectPolicy({ commit, state }, locale) {
    if(!availableTermsAndPolicies.includes(locale)){
      locale = 'en';
    }
    try {
      const result = await Data.getDataProtectPolicy(state.config, locale);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async getTaxInfo({ commit, state }, platform) {
    try {
      const result = await Data.getTaxInfo(state.config, platform);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async getPasswordInfo({ commit, state }) {
    try {
      const result = await Data.getPasswordInfo(state.config);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async getPrivacyPolice({ commit, state }, locale) {
    if(!availableTermsAndPolicies.includes(locale)){
      locale = 'en';
    }
    try {
      const result = await Data.getPrivacyPolicy(state.config, locale);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async getPartnersTermsOfUse({ commit, state }, partnerName) {
    try {
      const result = await Data.getPartnersTermsOfUse(state.config, partnerName);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  async sendResendOtp({ commit, state }) {
    try {
      const result = await Data.sendResendOtp(state.config, state.fetchUUID);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return undefined;
    }
  },
  setConfig({ commit }, config) {
    commit(types.SET_CONFIG, config);
  },
  setNextDatasource({ commit }, value) {
    commit(types.SET_NEXT_DATASOURCE, value);
  },
  async sendFile({ commit, state }, file) {
    try {
      const result = await Data.sendFile(state.config, file);
      return result;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
      return Error(undefined);
    }
  },
  setLoadMoreModal({ commit }, value) {
    commit(types.SET_LOAD_MORE_MODAL, value);
  },
  setTaxModal({ commit }, value) {
    commit(types.SET_TAX_MODAL, value);
  },
  setDateModal({ commit }, value) {
    commit(types.SET_DATE_MODAL, value);
  }
};
