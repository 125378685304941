<template>
  <div class="page page--fetch" :class="{ rtl: isRtl }">
    <Header />
    <div  class="page__animation-wrapper">
      <div class="page__animation-container" v-show="bodymovinInstance">
        <div
          class="page__animation-icon"
          :class="{
            'page__animation-icon--merged': getSelectedSource?.merged_logo,
          }"
        >
          <img
            v-if="getSelectedSource?.merged_logo"
            :src="getSelectedSource.merged_logo"
            alt="Logo" />
          <img
            v-else
            :src="getAvatar(getSelectedSource)"
            alt="Logo" />
        </div>
        <div  class="page__animation" id="animation" />
      </div>
    </div>
    <ul class="page__progress-wrapper">
      <li
        class="page__progress__item"
        :class="{
          'page__progress__item-fetching': loading.connectionStatus === 'loading',
          'page__progress__item-done': loading.connectionStatus === 'done',
        }">
        <div class="page__progress__item-icon">
          <Transition name="fade" mode="out-in">
            <component :is="connectionLoading" />
          </Transition>
          <i />
        </div>
        <Translate class="page__progress__item-text" :translate="'fetchConnection'" />
      </li>
      <li
        class="page__progress__item"
        :class="{
          'page__progress__item-fetching': loading.encryptionStatus === 'loading',
          'page__progress__item-done': loading.encryptionStatus === 'done',
        }">
        <div class="page__progress__item-icon">
          <Transition name="fade" mode="out-in">
            <component :is="encryptionLoading" />
          </Transition>
          <i />
        </div>
        <Translate class="page__progress__item-text" :translate="'fetchCredentials'" />
      </li>
      <li
        class="page__progress__item"
        :class="{
          'page__progress__item-fetching': loading.authenticationStatus === 'loading',
          'page__progress__item-done': loading.authenticationStatus === 'done',
          'page__progress__item-error': loading.authenticationStatus === 'error',
        }">
        <div class="page__progress__item-icon">
          <Transition name="fade" mode="out-in">
            <component :is="authenticationLoading" />
          </Transition>
        </div>
        <Translate class="page__progress__item-text" :translate="'fetchAuthentication'" />
      </li>
    </ul>
    <Translate class="page__progress__text" :translate="'fetchPleaseWait'" />
    <CodeModal
      v-if="getCodeModal"
      @startLoop="onLoop"
      @resend="onResend" />
    <ApprovalCodeModal
      v-if="getApprovalWithCodeModal"
      @startLoop="onLoop"
      @resend="onResend" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Header from '@/components/Header';
import SuccessOutlinedIcon from '@/components/icons/SuccessOutlinedIcon';
import ClosedOutlinedIcon from '@/components/icons/ClosedOutlinedIcon';
import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';
import CodeModal from '@/components/CodeModal';
import Analytics from '@/api/analytics';
import ApprovalCodeModal from '@/components/ApprovalCodeModal';

export default {
  name: 'Fetch',
  components: {
    ApprovalCodeModal,
    Header,
    SuccessOutlinedIcon,
    ClosedOutlinedIcon,
    LoadingSpinnerIcon,
    CodeModal,
  },
  data() {
    return {
      animate: false,
      hidden: false,
      connect: true,
      WAIT_FOR_PROPER_RESPONSE: null,
      fetchStatus: undefined,
      stdError: undefined,
      bodymovinInstance: undefined,
      loginDataReset: {
        username: undefined,
        password: undefined,
      },
      loading: {
        // 'loading' || 'done' || 'error'
        connectionStatus: 'loading',
        encryptionStatus: undefined,
        authenticationStatus: undefined,
      },
    };
  },
  mixins: ['Localization'],
  mounted() {
    if (this.getRefreshed) {
        this.waitingForLoopResponse(this.getRefreshed);
      } else if (
        this.getSelectedSource &&
        (
          this.getLoginCredentials.username ||
          this.getLoginCredentials.password ||
          this.getLoginCredentials.date
        )
      ) {
        this.animate = false;
        this.connectToSource();
      } else {
        this.$router.push({name: 'Terms', query: this.$route.query});
        return;
      }
    this.setupMovinAnimation();
    this.startLoadingInterval();
  },
  unmounted() {
    clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
    // Reset loading
    this.loading = {
      connectionStatus: 'loading',
      encryptionStatus: undefined,
      authenticationStatus: undefined,
    };
    this.WAIT_FOR_PROPER_RESPONSE = null;
  },
  computed: {
    ...mapGetters([
      'getSelectedSource',
      'getConnectLogoPosition',
      'getLoginCredentials',
      'getError',
      'translations',
      'getRefreshed',
      'getCodeModal',
      'getFetchUUID',
      'getApprovalWithCodeModal',
      'sources',
    ]),
    connectionLoading() {
      return this.loading.connectionStatus === 'loading'
        ? 'LoadingSpinnerIcon'
        : 'SuccessOutlinedIcon';
    },
    encryptionLoading() {
      return this.loading.encryptionStatus === 'loading'
        ? 'LoadingSpinnerIcon'
        : 'SuccessOutlinedIcon';
    },
    authenticationLoading() {
      switch (true) {
        case this.fetchStatus === 'error' && this.loading.authenticationStatus === 'error':
          return 'ClosedOutlinedIcon';
        case this.loading.authenticationStatus === 'loading':
          return 'LoadingSpinnerIcon';
        default:
          return 'SuccessOutlinedIcon';
      }
    },
  },
  methods: {
    ...mapActions(['loopForStatus', 'setNextDatasource']),
    async setupMovinAnimation() {
      // eslint-disable-next-line
      const bodymovinInstance = await bodymovin.loadAnimation({
        container: document.getElementById('animation'),
        renderer: 'svg',
        // eslint-disable-next-line import/extensions,global-require,import/no-unresolved
        animationData: require('@/../public/animations/fetch-animation.json'),
        name: 'fetchAnimation',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
          progressiveLoad: true,
          runExpressions: true,
        },
      });
      setTimeout(() => {
        this.bodymovinInstance = bodymovinInstance;
      }, 100);
    },
    startLoadingInterval() {
      // Indicate start of loading credentials
      this.loadingSteps('connectionStatus', 'loading');
      const interval = setInterval(() => {
        switch (true) {
          // Fake Credentials check/loading
          case this.loading.connectionStatus === 'loading':
            this.loadingSteps('connectionStatus', 'done');
            this.loadingSteps('encryptionStatus', 'loading');
            break;
          // Fake Account check/loading
          case this.loading.encryptionStatus === 'loading':
            this.loadingSteps('encryptionStatus', 'done');
            this.loadingSteps('authenticationStatus', 'loading');
            break;
          // Await BA fetch status
          case this.loading.authenticationStatus === 'loading'
            && this.fetchStatus === 'connected'
            && this.getSelectedSource?.next_datasource_id === null:
          case this.loading.authenticationStatus === 'loading'
            && this.fetchStatus === 'complete'
            && this.getSelectedSource?.next_datasource_id === null:
            this.loadingSteps('authenticationStatus', 'done');
            this.bodymovinInstance.pause();
            clearInterval(interval);
            break;
          case this.loading.authenticationStatus === 'loading' && this.fetchStatus === 'error':
            // Check if error-std is not credentials-code
            // if true, stay on fetch screen
            if (this.stdError !== 'credentials-code') {
              this.loadingSteps('authenticationStatus', 'error');
              this.bodymovinInstance.pause();
              clearInterval(interval);
              setTimeout(() => {
                this.$router.push({ name: 'Login', query: this.$route.query });
              }, 1000);
            }
            break;
          default:
            break;
        }
      }, 2000);
    },
    loadingSteps(onStep, status) {
      this.loading[onStep] = status;
    },
    onLoop(startLoop) {
      if (startLoop) {
        this.waitingForLoopResponse(this.$store.state.fetchUUID);
        return;
      }
      clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
    },
    onResend() {
      this.$store.dispatch('sendResendOtp');
    },
    connectToSource() {
      if(!this.getSelectedSource){
        const selectedSource = JSON.parse(localStorage.getItem('selected_source'));
        if(selectedSource){
          this.$store.commit('SET_SELECTED_SOURCE', selectedSource);
        }
      }
      this.$store.dispatch('connectToSource', this.getSelectedSource).then((res) => {
        if (res) {
          window.rolleeWorker.postMessage({
            action: 'getSessionDuration',
            fromScreen: 'Login',
            toScreen: 'LoginResponse',
            screenID: '6',
          });
          const description = this.checkForError(res);
          // check object has property
          if (res.uuid) {
            this.waitingForLoopResponse(res.uuid);
          } else {
            this.fetchStatus = res.status;
            this.stdError = res['error-std'];
            switch (res.status) {
              case 'connected':
                this.$store.commit('SET_FETCH_UUID', res.uuid);
                this.$store.commit('SET_ACCOUNT_ID', res?.account_id);
                this.$store.commit('SET_LOGIN_CREDENTIALS', undefined);
                this.clearSelectedSourceLocal();
                break;
              case 'code':
                clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                this.$store.commit('SET_FETCH_UUID', res.uuid);
                this.displayModal(res);
                break;
              case 'error':
                clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                this.$store.commit('SET_ERROR', {
                  name: 'Error',
                  description,
                  'error-std':res['error-std'],
                });

                Analytics.dispatch({
                  action: 'fetchingError',
                  fromScreen: this.$route.name,
                  toScreen: 'Login',
                  status: res.status,
                  errorType: res['error-std'],
                });
                // Comment back in when BA can handle re-try code
                if (this.stdError === 'credentials-code') {
                  this.displayModal(res);
                  return;
                }
                this.removeModal();
                this.clearSelectedSourceLocal();
                break;
              case 'complete':
                clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                this.$store.dispatch('getConnectedSources');
                this.$store.commit('SET_LOGIN_CREDENTIALS', undefined);
                this.removeModal();
                this.clearSelectedSourceLocal();
                break;
              default:
                this.$store.commit('SET_ERROR', {
                  name: 'OOOPS...',
                  description: 'Something went wrong',
                });
                this.removeModal();
                this.clearSelectedSourceLocal();
                break;
            }
          }
        } else {
          this.animate = false;
          this.hidden = true;
          window.rolleeWorker.postMessage({
            action: 'getSessionDuration',
            fromScreen: 'Login',
            toScreen: 'LoginResponse',
            screenID: '6',
          });
          this.$store.commit('SET_ERROR', {
            name: 'OOOPS...',
            description: 'Something went wrong',
          });
          this.removeModal();
        }
      });
    },

    checkForError(response) {
      let description = this.$store.state.errorConnectingDataSource;
      if (response['error-std'] && this.translations.errors[response['error-std']]) {
        description = this.translations.errors[response['error-std']];
      } else if (response['error-info']) {
        description = response['error-info'];
      }

      if(this.getSelectedSource.url === 'agencia-tributaria'){
        switch (response['error-std']) {
          case 'credentials':
            description = this.translations.platformErrors['agencia-tributaria']['credentials'];
            break
          case 'credentials-username':
            description = this.translations.platformErrors['agencia-tributaria']['credentials-username'];
            break;
          case 'credentials-password':
            description = this.translations.platformErrors['agencia-tributaria']['credentials-password'];
            break;
          default:
            description = this.translations.errors[response['error-std']];
            break;
        }
      }
      return description;
    },
    // loop every 2 seconds
    waitingForLoopResponse(uuid) {
      this.$store.commit('SET_FETCH_UUID', uuid);
      this.WAIT_FOR_PROPER_RESPONSE = setInterval(() => {
        this.$store.dispatch('loopForStatus', uuid).then((res) => {
          this.fetchStatus = res.status;
          this.stdError = res['error-std'];
          if (navigator.onLine) {
            if (res.status === 'connected' || res.status === 'complete') {
              // eslint-disable-next-line
              if (this.getSelectedSource?.next_datasource_id === null || this.getSelectedSource?.next_datasource_id === undefined) {
                const loginSuccess = {
                  onLoginSuccess: true,
                  datasourceID: this.getSelectedSource.ID,
                  accountID: res.account_id || null,
                };
                this.$store.commit('SET_ACCOUNT_ID', res?.account_id);
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(JSON.stringify(loginSuccess));
                } else {
                  window.top.postMessage(JSON.stringify(loginSuccess), '*');
                  // eslint-disable-next-line
                  window.RolleeConnectFlutter && window.RolleeConnectFlutter.postMessage(JSON.stringify(loginSuccess));
                }
                // eslint-disable-next-line no-console
                console.warn('onLoginSuccess!');
                this.$store.commit('SET_LOGIN_CREDENTIALS', undefined);
                clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                this.WAIT_FOR_PROPER_RESPONSE = null;
                this.removeModal();
                setTimeout(() => {
                  this.clearSelectedSourceLocal();
                  this.$router.push({ name: 'Success', query: this.$route.query });
                }, 1500);
              } else {
                const loginSuccess = {
                  onLoginSuccess: true,
                  datasourceID: this.getSelectedSource.ID,
                  accountID: res.account_id || null,
                };
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(JSON.stringify(loginSuccess));
                } else {
                  window.top.postMessage(JSON.stringify(loginSuccess), '*');
                  // eslint-disable-next-line
                  window.RolleeConnectFlutter && window.RolleeConnectFlutter.postMessage(JSON.stringify(loginSuccess));
                }
                clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
                // eslint-disable-next-line
                const nextDatasourceId = this.getSelectedSource.next_datasource_id;
                this.setNextDatasource(nextDatasourceId);
                this.connectToSource();
                this.removeModal();
              }
            } else if (res.status === 'error') {
              clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
              this.WAIT_FOR_PROPER_RESPONSE = null;
              const description = this.checkForError(res);
              this.$store.commit('SET_ERROR', {
                name: 'Error',
                description,
                'error-std': res['error-std'],
              });
              this.$store.commit('SET_ACCOUNT_ID', res?.account_id);
              Analytics.dispatch({
                action: 'fetchingError',
                fromScreen: this.$route.name,
                toScreen: 'Login',
                status: res.status,
                errorType: res['error-std'],
              });
              // Comment back in when BA can handle re-try code
              if (this.stdError === 'credentials-code') {
                this.displayModal(res);
                return;
              }
              this.removeModal();
              this.clearSelectedSourceLocal();
            } else if (res.status === 'code') {
              this.displayModal(res);
            }
          } else {
            // eslint-disable-next-line no-console
            console.warn('We are offline');
            this.removeModal();
            this.clearSelectedSourceLocal();
          }
        });
      }, 2000);
    },
    displayModal(fetchStatus) {
      this.$store.commit('SET_FETCH_STATUS', fetchStatus);
      // Display OTP/MFA modal based on the otp-status property
      switch (fetchStatus['otp-status']) {
        case 'MFA_APPROVAL':
          this.$store.commit('SET_APPROVAL_MODAL', true);
          clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
          break;
        case 'APPROVAL':
          this.$store.commit('SET_APPROVAL_MODAL', true);
          break;
        case 'OTP_DEVICE_APPROVAL':
          this.$store.commit('SET_APPROVAL_WITH_CODE_MODAL', true);
          break;
        default:
          this.$store.commit('SET_CODE_MODAL', true);
          clearInterval(this.WAIT_FOR_PROPER_RESPONSE);
      }
    },
    removeModal() {
      this.$store.commit('SET_CODE_MODAL', false);
      this.$store.commit('SET_APPROVAL_MODAL', false);
      this.$store.commit('SET_APPROVAL_WITH_CODE_MODAL', false);
    },
    clearSelectedSourceLocal(){
      localStorage.removeItem('selected_source')
    }
  },
};
</script>

<style lang="scss" scoped>
.page{
  &--fetch{
    display: grid;
    grid-template-rows: 25px 277px auto;
    align-content: flex-start;
    width: 100%;
    height: 100%;
  }

  &__animation{
    position: absolute;
    width: 260px;
    height: 260px;
    &-wrapper{
      height: 260px;
      width: 260px;
      margin-top: 17px;
    }
    &-container{
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-icon{
      width: 48px;
      height: 48px;
      border-radius: 999px;
      z-index: 10;

      & img{
        width: 100%;
        height: 100%;
        border-radius: 999px;
      }
    }
  }

  &__progress{
    &-wrapper{
      padding: 0;
      list-style: none;
    }

    &__text{
      margin-top: 30px;
      transition: color 0.5s ease-in;
      color: $dark-grey;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.5px;
      padding: 0 46px;
    }

    &__item{
      display: grid;
      grid-template-columns: 18px auto;
      align-items: center;
      column-gap: 12px;
      margin-bottom: 12px;
      &-spinner{
        opacity: 0;
      }
      &-icon{
        color: $light-grey;
        transition: color 1s ease-in;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & svg{
          transition: opacity 0.5s cubic-bezier(0.75, 0.38, 0.83, 1.05);
        }
        & i{
          position: absolute;
          height: 12px;
          width: 1.5px;
          left: 8px;
          background-color: $light-grey;
          transform: translateY(16px);
          opacity: 0;
          transition: opacity 0.2s linear 0.2s;
        }

        & svg{
          height: 18px;
          width: 18px;
        }
      }

      &-text{
        color: $light-grey;
        transition: color 0.5s ease-in;;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
      }
      &-done{
        & .page__progress__item{
          &-icon{
            color: #8FB571;

            & i{
              opacity: 1;
            }
          }

          &-text{
            color: $black;
          }
        }
      }
    }
  }
}

.fade-leave-active {
  transition: opacity 500ms ease-out;
}

.fade-enter-active{
  transition: opacity 1000ms ease-in;

}
.fade-enter{
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
</style>
